import React, { Component } from 'react';
import Slider from "react-slick";

// Images
import Image1 from "../../../images/slider/slide5.jpg"
import Image2 from "../../../images/slider/slide6.jpg"

class Slider2 extends Component{
	
	constructor(props) {
		super(props);
		this.state = {

		
			data3: [],

			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}

	componentDidMount() {

			const apiUrl3 = "https://denizcicekcilikadmin.stechomeyazilim.info:7072/getSliderDnz/select/";

			fetch(apiUrl3)
				.then((response) => response.json())
				.then((data3) => {
					this.setState({ data3 });
				})
				.catch((error) => {
					console.error("API'den veri çekme hatası:", error);
				});

				

	}
	render(){
		
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		
		return(
			<>
				
				<Slider {...settings} className="tt-slider slider-sp0 slider-two">

				{this.state.data3.map((item) => (

					<div className="slider-item">
						<div className="slider-thumb ovbl-middle">
							<img src={Image1} alt=""/>
						</div>
						<div className="slider-content text-center text-white">
							<div className="container">
								<div className="content-inner">
									<h6 className="sub-title">{item.SliderTitle}</h6>
									<h2 className="title">Solar Wind<br/>Solar Solutions</h2>
									<a className="btn primary" href="/contact-1">Contact Us</a>
								</div>
							</div>
						</div>
					</div>		))}	
							
				</Slider>
				
			</>
		);
	}
}

export default Slider2;