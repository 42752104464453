import React, { Component, Fragment } from 'react';

import { useLocation, Link } from "react-router-dom";
import Hizmetdetay from './hizmetdetay';
import BlogDetailsSidebar from './blog-details-sidebar';
import Solag_ from './solag_';

const Blogdetails = () => {
    const location = useLocation();
    const state = location.state;

	console.log("smdfşklcc",state)
    window.scrollTo(0, 0);

        return (
            <Fragment>
                <Solag_ _product={state._product} _item={state._item} _allCategory={state._allCategory}/>
            </Fragment>
        );
    
}

export default Blogdetails;