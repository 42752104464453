import React, { Component, Fragment } from 'react';

import { useLocation, Link } from "react-router-dom";
import Hizmetdetay_ from './hizmetdetay_';

const Blogdetails = () => {
    const location = useLocation();
    const state = location.state;

    window.scrollTo(0, 0);

        return (
            <Fragment>
                <Hizmetdetay_ _category = {state._category} _item={state._item}/>
            </Fragment>
        );
    
}

export default Blogdetails;