import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import SolutionSidebar from '../elements/services/solution-sidebar';

// Images
import Banner1 from '../../images/banner/banner1.jpg';
import ReactHtml from 'raw-html-react';
import { withTranslation } from 'react-i18next';
import utf8 from "utf8";
import base64 from 'react-native-base64';

class SolutionAgriculturalEngineering extends Component {
  handleLinkClick = (item) => {
    // Handle the click event, you can update the state or perform other actions
    // For example, you might want to update a selected item in the state
    // and then use that selected item to display the corresponding content.

    // For simplicity, let's assume you have a state property called selectedServiceItem
    this.setState({ selectedServiceItem: item });
  };
  constructor(props) {
    super(props);
    this.state = {

      data3: [],

      data4: [],
      imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
      selectedServiceItem: null, // Initialize with null or an empty object
    };
  }

  componentDidMount() {
    const { _item, _itemim ,_itemm} = this.props.location.state;

    const apiUrl3 = "https://denizcicekcilikadmin.stechomeyazilim.info:7072/getConsultancyDnzFilter2/select/" + _item;
    // Fetch data for consultancy

  

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data3) => {
        this.setState({ data3 });

      })
      .catch((error) => {
        console.error("Engineering Services API'den veri çekme hatası:", error);
      });




  }



  render() {
    const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir



    const language = i18n.language

    // Decode base64



    return (
      <>
        <Header1 />

        {/* <!-- Content --> */}
        <div className="page-content bg-white">
          <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: `url(${Banner1})` }}>
            <div className="container">
              <div className="page-banner-entry">
                <h1 className="text-white">{t('DH')}</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li><Link to="/"><i className="fa fa-home"></i> {t('Anasayfa')}</Link></li>
                    <li>{t('DH')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="content-block" id="content-area">
            <div className="section-area section-sp2 bg-fix bg-white">
              <div className="container about-video">
                <div className="row">
              
       
              



                    <div className="col-lg-12 col-md-7 service-textarea wow fadeInUp portfolio-content" data-wow-delay="0.4s">
                      <div className="heading-bx text-left m-b20">
                      {this.state.data3.map((item) => (
                          <h3 className="title-head m-b0">{item.Title}</h3> 
      
                      ))}
                      </div>

                      {this.state.data3.map((item) => (
                      
                      <div className="row ">



                        <div className="col-md-12 m-b15">
                          <img
                            src={this.state.imageUrl + item.ConsultancyImage}
                            className="radius-sm imghight"
                            alt=""
                            style={{
                              height: '300px', // Sabit yükseklik değeri (istediğiniz değeri girebilirsiniz)
                              width: '100%',
                              objectFit: 'cover'
                            }}
                          />

                        </div>

                        {language == "en" ? 
                     <ReactHtml html={utf8.decode(base64.decode(item.ConsultancyDescEng))}  /> :
                     <ReactHtml html={utf8.decode(base64.decode(item.ConsultancyDesc))}  />}


                      </div>
                           ))}
                    </div>


  
                </div>
              </div>
            </div>
          </div>

          <Footer1 />
        </div>
      </>
    );
  }
}

export default withTranslation()(SolutionAgriculturalEngineering); // withTranslation ile sarmalandı
