import React, { Component, Fragment } from 'react';

import { useLocation, Link } from "react-router-dom";
import Hizmetdetay from './hizmetdetay';

const Blogdetails = () => {
    const location = useLocation();
    const state = location.state;

	console.log("smdfşkl",state)
    window.scrollTo(0, 0);

        return (
            <Fragment>
              
             
                <Hizmetdetay _item={state._item}/>
               
            </Fragment>
        );
    
}

export default Blogdetails;