import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import SolutionSidebar from '../elements/services/solution-sidebar';

// Images
import Banner1 from '../../images/banner/banner1.jpg';
import ReactHtml from 'raw-html-react';
import { withTranslation } from 'react-i18next';
import utf8 from "utf8";
import base64 from 'react-native-base64';
import { Base64 } from 'js-base64';

class SolutionAgriculturalEngineering extends Component {
	handleLinkClick = (item) => {
		// Check if the selected item is already the one that is selected
		if (this.state.selectedServiceItem && this.state.selectedServiceItem.ID === item.ID) {
			return; // Skip updating the state if it's the same item
		}
	
		this.setState({ selectedServiceItem: item });
	};
	
	
	constructor(props) {
		super(props);
		this.state = {
			data4: [],
			data3: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
			selectedServiceItem: null, // Initialize with null or an empty object
		};
	}

	componentDidMount() {
		const { _category } = this.props;
		console.log("klnsxdfcck",_category.ID)
		const apiUrl4 = "https://denizcicekcilikadmin.stechomeyazilim.info:7072/getProductsDnzSelect/select/" + _category.ID;


		// Fetch data for engineering services
		fetch(apiUrl4)
			.then((response) => response.json())
			.then((data4) => {
				this.setState({ data4 });
				console.log("data2124", data4);
			})
			.catch((error) => {
				console.log("Engineering Services API'den veri çekme hatası:", error);
			});
	}

	render() {
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir


		const { selectedServiceItem } = this.state;
		const language = i18n.language

		// Decode base64

		const { _item,_category } = this.props;

		return (
			<>
				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					<div className="page-banner ovbl-dark parallax" style={{ backgroundImage: `url(${Banner1})` }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{_category.Products_Category_Title}</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> {t('Anasayfa')}</Link></li>
										<li>{_category.Products_Category_Title}</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="content-block" id="content-area">
						<div className="section-area section-sp2 bg-fix bg-white">
							<div className="container about-video">
								<div className="row">
									<div className="col-lg-4 col-md-5 m-b30 wow fadeInUp" data-wow-delay="0.2s">
										<aside className="sticky-top">
											<div className="widget">
												<ul className="service-list">
													{this.state.data4.map((item) => (
														
															<li key={item.ID}>
																<Link   to={{
                              pathname: `/hizmetdetayAra_/${item.ID}`,
                            }}
							state= {{ _item : item, _category:_category }}>
																	 <span>{item.Products_Title}</span>
																	<i className="fa fa-angle-right"></i>
																</Link>
															</li>
														
													))}
												</ul>
											</div>


										</aside>
									</div>


									{selectedServiceItem == null ?

										<div className="col-lg-8 col-md-7 service-textarea wow fadeInUp portfolio-content" data-wow-delay="0.4s">
											<div className="heading-bx text-left m-b20">
												
													<h3 className="title-head m-b0">{_item.Products_Title}</h3>
												
												<div className="ttr-separator sepimg"></div>
											</div>
											<div className="row ">



												<div className="col-md-12 m-b15">
													<img
														src={this.state.imageUrl + _item.Products_MainImage}
														className="radius-sm imghight"
														alt=""
														style={{
															height: '300px', // Sabit yükseklik değeri (istediğiniz değeri girebilirsiniz)
															width: '100%',
															objectFit: 'cover'
														}}
													/>

												</div>

												<p>{_item.Products_ShortDesc}</p>

											{	_item.Products_Desc != null ? 
												<div dangerouslySetInnerHTML={{ __html: Base64.decode(_item.Products_Desc) }} />:null}

											</div>


										</div>


										: null}


									{selectedServiceItem && (
										<div className="col-lg-8 col-md-7 service-textarea wow fadeInUp portfolio-content" data-wow-delay="0.4s">
											<div className="heading-bx text-left m-b20">
												{language === "en" ? <h3 className="title-head m-b0">{selectedServiceItem.TitleEng}</h3> : <h3 className="title-head m-b0">{selectedServiceItem.Title}</h3>}
												<div className="ttr-separator sepimg"></div>
											</div>
											<div className="row">
												<div className="col-md-12 m-b15">
													<img
														src={this.state.imageUrl + selectedServiceItem.PacketsMemberImage}
														className="radius-sm imghight"
														alt=""
														style={{
															height: '300px',
															width: '100%',
															objectFit: 'cover'
														}}
													/>
												</div>

												<p>
													{language == "en" ? selectedServiceItem.DescriptionEng :
														selectedServiceItem.Description}

												</p>

											
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>

					<Footer1 />
				</div>
			</>
		);
	}
}

export default withTranslation()(SolutionAgriculturalEngineering); // withTranslation ile sarmalandı
