import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';
import Banner1 from '../../images/banner/banner1.jpg';
import Icon1 from '../../images/icon/contact/icon1.png';
import Icon2 from '../../images/icon/contact/icon2.png';
import Icon3 from '../../images/icon/contact/icon3.png';
import { withTranslation } from 'react-i18next'; // withTranslation eklenmiş
import 'toastr/build/toastr.min.css'; // toastr stillerini içe aktarın
import toastr from 'toastr'; // toastr kütüphanesini içe aktarın


class GetInTouch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data2: [],
      data3: [],
      selectedOptions: [], // birden çok seçim için dizi eklendi
      imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
      isFormSubmitted: false,
      offerID: null // OfferID için state ekleniyor
    };
  }

  handleCheckboxChange = (selectedID) => {
    // Seçilen seçeneğin dizide olup olmadığını kontrol ediyoruz
    if (this.state.selectedOptions.includes(selectedID)) {
      // Seçenek zaten seçilmişse, seçimi kaldırıyoruz

      this.setState(prevState => ({
        selectedOptions: prevState.selectedOptions.filter(id => id !== selectedID)
      }));
    } else {
      // Seçenek daha önce seçilmemişse, diziye ekliyoruz
      this.setState(prevState => ({
        selectedOptions: [...prevState.selectedOptions, selectedID]
      }));
    }
  };

  componentDidMount() {

    const apiUrl2 = 'https://denizcicekcilikadmin.stechomeyazilim.info:7072/getContactDnz/select/';


    const apiUrl3 = 'https://denizcicekcilikadmin.stechomeyazilim.info:7072/getProductsCategoryDnz/select/';

    fetch(apiUrl2)
      .then((response) => response.json())
      .then((data2) => {
        this.setState({ data2 });
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data3) => {
        this.setState({ data3 });
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }


  submitForm = (event) => {
		event.preventDefault(); // Formun varsayılan davranışını engelle
		
		// Form verilerini al
		const name = document.querySelector('input[name="NameSurname"]').value;
		const email = document.querySelector('input[name="Mail"]').value;
		const phone = document.querySelector('input[name="TelNo"]').value;
		const subject = document.querySelector('input[name="ProjectSubject"]').value;
		const message = document.querySelector('textarea[name="HelpTopic"]').value;
		const CompanyName = document.querySelector('input[name="CompanyName"]').value;
    let altBody = ''; // Boş bir metin dizesi oluşturun

    // Tüm seçili seçenekleri dolaşarak altBody metin dizesine ekleyin
    this.state.selectedOptions.forEach(item => {
        altBody += item + '\n'; // Her bir seçeneği yeni bir satıra ekleyin
    });

    console.log("altBody123",altBody)

		const data = {
			to_email : 'info@castordanismanlik.com',
			subject : subject,
			message : CompanyName + " firması "+ name + " isimli kişi, "+ phone + " telefon numaralı ve " + email + " mail adresli kişinin mesajı şu şekilde" + message + " ve seçili hizmetler : " + altBody,
		}
		

		// JSON verilerini POST isteğiyle gönder
		fetch('https://denizcicekcilikadmin.stechomeyazilim.info:7072/sendMailCastor/send', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then(response => response.json())
		.then(data => {
			// İşlem başarılıysa
			if (data.success) {
				// İşlem sonucunu ekrana göster
				console.log('İşlem başarıyla tamamlandı:', data.message);
				// İşlem sonrası yapılacakları buraya yazabilirsiniz
			} else {
				this.showToast(true)
				// İşlem başarısızsa
				console.error('İşlem sırasında bir hata oluştu:', data.error);
				// İşlem sonrası yapılacakları buraya yazabilirsiniz
			}
		})
		.catch(error => {
			// Hata oluştuğunda
			this.showToast(true)
			//console.error('Bir hata oluştu:', error);
			// İşlem sonrası yapılacakları buraya yazabilirsiniz
		});
	};

  handleSubmit = async (event) => {
    event.preventDefault();

    // Form verilerini al
    const formData = new FormData(event.target);
    const jsonData = {};

    // FormData'dan JSON verisi oluştur
    formData.forEach((value, key) => {
      jsonData[key] = value;
    });

    // FormData'ya seçilen seçenekleri ekle
    const selectedOption = this.state.selectedOptions;
    if (selectedOption.length > 0) {
      jsonData.selectedOptions = selectedOption;
    }

    // API istekleri
    const apiUrl = 'https://denizcicekcilikadmin.stechomeyazilim.info:7072/addOfferDnz/send/';
    const apiUrl2 = 'https://denizcicekcilikadmin.stechomeyazilim.info:7072/addOfferServiceDnz/send/';

    try {
      // Ana bilgileri gönder
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ID: 2,
          NameSurname: jsonData.NameSurname,
          TelNo: jsonData.TelNo,
          Mail: jsonData.Mail,
          CompanyName: jsonData.CompanyName,
          ProjectSubject: jsonData.ProjectSubject,
          HelpTopic: jsonData.HelpTopic,
        }),
      });

      const result = await response.json();

      console.log('result:', result); // Dönen veriyi konsola yazdır
      console.log('resultID:', result.ID); // Dönen veriyi konsola yazdır
      // İlk post işleminden gelen ID'yi al
      const offerID = result.ID; // offerID'yi burada alın

      console.log('offerID:', offerID); // offerID'yi konsola yazdır

      // OfferID'yi state'e kaydedin
      this.setState({ offerID }, async () => {
        // Hizmet seçeneklerini gönder
        const promises = jsonData.selectedOptions.map(async (selectedOptionID) => {
          const response2 = await fetch(apiUrl2, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              OfferID: this.state.offerID, // State'ten OfferID'yi kullan
              ServiceID: selectedOptionID,
            }),
          });
          return response2.json(); // Promise döndür
        });

        const results2 = await Promise.all(promises); // Tüm isteklerin tamamlanmasını bekle

        console.log('results2:', results2[0].value); // Tüm yanıtları konsola yazdır

        // Başarılı bir şekilde gönderildiğini kontrol et
        if (results2[0].value.length > 0) {
          this.setState({ isFormSubmitted: true });
          this.showToast(true)
        }
      });
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

   showToast= async (type) => {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Talebiniz iletilmiştir."
    } else{
       ele = "error"
       message = "type"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }


  render() {
    const { data3 } = this.state;
    const { t, i18n } = this.props;
    const language = i18n.language;
    return (
      <>
        <Header1 />
        <div className="page-content bg-white">
          <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: `url(${Banner1})` }}>
            <div className="container">
              <div className="page-banner-entry">
                <h1 className="text-white">{t('TeklifAl')}</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to="/">
                        <i className="fa fa-home"></i> {t('Anasayfa')}
                      </Link>
                    </li>
                    <li>{t('TeklifAl')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="content-block" id="content-area">
            <div className="section-area bg-gray section-sp2">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-7">
                    <div className="heading-bx">
                      <h6 className="title-ext m-b0">Deniz Çiçekçilik</h6>
                      <h3 className="title-head m-b0">{t('TeklifAl')}</h3>
                      <p className="head-px2">{t('FED')}</p>
                    </div>
                    <div className="row m-b30">
                      <div className="col-md-12">
                        <form className="ajax-form form-area-box get-in-touch" onSubmit={this.submitForm}>
                          <div className="ajax-message">
                            {this.state.isFormSubmitted && (
                              <div className="alert alert-success">
                               Talebiniz iletilmiştir.
                              </div>
                            )}
                          </div>
                          <div className="row placeani">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="input-group">
                                  <input placeholder={t('İsminiz')} name="NameSurname" type="text" className="form-control" required />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="input-group">
                                  <input placeholder={t('Firma')} name="CompanyName" type="text" className="form-control" required />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="input-group">
                                  <input placeholder='Email' name="Mail" type="text" className="form-control" required />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="input-group">
                                  <input placeholder={t('Telefon')} name="TelNo" type="text" className="form-control" required />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <div className="input-group">
                                  <input placeholder={t('Konu')} name="ProjectSubject" type="text" className="form-control" required />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <div className="input-group">
                                  <textarea placeholder={t('Mesaj')} name="HelpTopic" className="form-control" required></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <h6 style={{color:'blue'}} className="title-ext m-b0">Ürün Kategorisi Seçiniz</h6>
                                <div className="check-btn" data-toggle="buttons">
                                  <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                                    {data3.map((item) => (
                                      <div key={item.ID} className="form-check form-check-inline" style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id={`option-${item.Products_Category_Title}`}
                                          checked={this.state.selectedOptions.includes(item.Products_Category_Title)}
                                          onChange={() => this.handleCheckboxChange(item.Products_Category_Title)}
                                          style={{ display: 'none' }}
                                        />
                                        <label className="form-check-label" htmlFor={`option-${item.Products_Category_Title}`} style={{ fontSize: '16px', color: '#333', display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                                          <div className="custom-checkbox" style={{ width: '20px', height: '20px', borderRadius: '50%', border: '2px solid #333', marginRight: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {this.state.selectedOptions.includes(item.Products_Category_Title) && <span style={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: 'blue' }}></span>}
                                          </div>
                                     {item.Products_Category_Title}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                            <button onClick={this.submitForm} className="btn button-md">{t('Gönder')}</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer1 />
      </>
    );
  }
}

export default withTranslation()(GetInTouch);
